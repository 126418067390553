import { SEX } from "@energylab/shared-components/utils/sex";
import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";

export const sex = [
    { title: <FormatMessage id="auth.fields.male" />, value: SEX.male },
    { title: <FormatMessage id="auth.fields.female" />, value: SEX.female },
    { title: <FormatMessage id="auth.fields.nonbinary" />, value: SEX.other },
    { title: <FormatMessage id="auth.fields.noanswer" />, value: "no answer" }
];
