import { RedirectType } from "@energylab/shared-components/routes/routes";
import { lazy } from "react";

const ContentContainer = lazy(() => import("@energylab/shared-components/modules/pages/content/contentContainer"));

export const ROUTES = [
    {
        path: "faq/no-show",
        element: <ContentContainer type="faq_noshow" />
    },
    {
        path: "faq/my-apps",
        element: <ContentContainer type="faq_myapps" />
    },
    {
        path: "faq/events",
        element: <ContentContainer type="faq_events" />
    },
    {
        path: "faq/challenges",
        element: <ContentContainer type="faq_challenges" />
    },
    {
        path: "fitness",
        element: <ContentContainer type="fitness" />
    },
    {
        path: "fitness/gateway",
        element: <ContentContainer type="gatewayfitness" />
    },
    {
        path: "fitness/classes",
        element: <ContentContainer type="groupclasses" />
    },
];

export const REDIRECTS: RedirectType[] = [];
