import { ProfileConfig } from "@energylab/shared-components/constants/config/profileConfig";
import { routeConfig } from "@energylab/shared-components/constants/config/routeConfig";
import { UpdateProfileVariables } from "@energylab/shared-components/models/graphql/types";
import { profileStructure } from "../profileStructure";

export const profileConfig: ProfileConfig = {
    requiredProfileFields: [
        "givenName",
        "familyName",
        "department",
        "sex",
        "nationality"
    ],
    structure: profileStructure,
    formToValues: (values, originalValues): UpdateProfileVariables => {
        if (!originalValues) {
            throw Error("Can not update profile when not logged in");
        }

        return {
            profile: {
                ...originalValues,
                locale: String(originalValues.locale) || routeConfig.defaultLocale,
                givenName: String(values.givenName),
                familyName: String(values.familyName),
                profile: {
                    ...originalValues.profile,
                    nationality: values.nationality,
                    birthDate: values.birthDate,
                    sex: values.sex,
                    department: values.department
                },
                privacy: {
                    publicDisplayNameType: "fullname",
                    nickname: null,
                    showActivities: true,
                    showAsNewMember: true
                }
            }
        };
    },
    valuesToForm: (values) => {
        if (!values) {
            return {};
        }

        const profile = values.profile || {};

        return {
            givenName: values.givenName,
            familyName: values.familyName,
            nationality: values.nationality || "BEL",
            birthDate: profile.birthDate,
            sex: profile.sex,
            department: profile.department
        };
    }
};
