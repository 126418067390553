import * as React from "react";
import { FormatMessage } from "@energylab/shared-components/components/formatMessage/formatMessage";

export const departments = [{
    value: "deloitteAccountancy",
    label: <FormatMessage id="department.deloitteAccountancy" />,
    children: []
},
{
    value: "deloitteAra",
    label: <FormatMessage id="department.deloitteAra" />,
    children: []
},
{
    value: "deloitteConsulting",
    label: <FormatMessage id="department.deloitteConsulting" />,
    children: []
},
{
    value: "deloitteDsi",
    label: <FormatMessage id="department.deloitteDsi" />,
    children: []
},
{
    value: "deloitteFaa",
    label: <FormatMessage id="department.deloitteFaa" />,
    children: []
},
{
    value: "deloitteRa",
    label: <FormatMessage id="department.deloitteRa" />,
    children: []
},
{
    value: "deloitteTax",
    label: <FormatMessage id="department.deloitteTax" />,
    children: []
},
{
    value: "deloitteLaga",
    label: <FormatMessage id="department.deloitteLaga" />,
    children: []
}
];
