import { FormStructureScheme } from "@energylab/shared-components/components/UI/form/formBuilderManager/types";
import { departments } from "./departments";
import { sex } from "./sex";
import * as environment from "environment";
import { checkMinBirthDateCreator } from "@energylab/shared-components/utils/checkMinBirthDate";

export const profileStructure: FormStructureScheme = {
    type: "form",
    formElements: [
        {
            type: "row",
            property: "names",
            formElements: [
                {
                    title: "auth.fields.givenName",
                    placeholder: "auth.fields.givenName",
                    type: "text",
                    property: "givenName",
                    required: true
                },
                {
                    title: "auth.fields.familyName",
                    placeholder: "auth.fields.familyName",
                    type: "text",
                    property: "familyName",
                    required: true
                }
            ]
        },
        {
            title: "form.birthDate",
            type: "date",
            property: "birthDate",
            required: true,
            validationRules: [
                {
                    validatorBuilder: checkMinBirthDateCreator(environment.minYearOld)
                }
            ]
        },
        {
            title: "auth.fields.nationality",
            type: "text",
            defaultValue: "BEL",
            property: "nationality",
            disabled: true,
            hide: true
        },
        {
            title: "auth.fields.department",
            type: "select",
            property: "department",
            placeholder: "auth.fields.department",
            options: departments.map(dep => ({
                title: dep.label,
                value: dep.value
            })),
            required: true
        },
        {
            title: "auth.fields.sex",
            type: "radio",
            options: sex,
            property: "sex",
            required: true
        }
    ]
};
